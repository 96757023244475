import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import ComponenteConjuntoDeFiltros from '../../componentes/Filtros/ComponenteConjuntoDeFiltros'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {
  agregarFormatoFecha,
  aplicarFormatoMoneda,
} from '../../utilidades/funcionesComunes'
import moment from 'moment'
import {
  AgregarEmpresasACache,
  ObtenerCiudadPorId,
  ObtenerEmpresaPorId,
} from '../../cache/servicios-cache'
import {ObtenerTiqueterasGestionadas} from '../../microservicios'
import {FORMATOS_STANDARD, ROLES_APP} from '../../constantes'
import {
  EncabezadoFondo,
  TablaConPaginacionYFiltros,
} from '../../componentes/Tablas'
import InfoFacturaImpresion from '../../componentes/Factura/InfoFacturaImpresion'
import AlertaSimple from '../../componentes/Visuales/AlertaSimple'
import {CSVLink} from 'react-csv'
import {BotonSimple} from '../../componentes'
import {ContextApplication} from '../../contexto'
import {Boton} from '../../componentes/Botones'
import {Controller, useForm} from 'react-hook-form'
import clsx from 'clsx'
import {Input} from '../../componentes/inputs'
import {FachadaObtenerInformeTiqueterasGestionadas} from '../../microservicios/ControlVentaTiqueteras'
import {encabezadosReporteVerTiqueteras} from '../../componentes/CSV/EncabezadosArchivos'
import {useMsal} from '@azure/msal-react'
import FormularioAsignacionEntrega from '../../componentes/VentaTiqueteras/FormularioAsignacionEntrega'
import {ObtenerCiudadPorNombre} from '../../cache/servicios-cache/Ciudades'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const filtrosIniciales = {
  tamano: 10,
  pagina: 1,
  filtros: {
    fechaInicio: null,
    fechaFin: null,
    nit: null,
    verificacionNit: null,
    numeroLibreta: null,
    consecutivo: null,
    numeroFactura: null,
    ciudadId: null,
    sedeId: null,
  },
}

const ROLES_VISUALIZACION = [ROLES_APP.JEFE_SEDE, ROLES_APP.GERENTE_COMERCIAL]

function GenerarReporte({generarReporte}) {
  const contextoAplicacion = useContext(ContextApplication)
  const form = useForm({defaultValues: {fechaInicio: null, fechaFin: null}})

  const fechaInicio = form.watch('fechaInicio')
  const fechaFin = form.watch('fechaFin')

  const configuracionFechaInicio = useMemo(() => {
    form.trigger('fechaFin')
    return {
      fechaMinima: fechaFin
        ? moment(fechaFin).add(-6, 'months').format('YYYY-MM-DD')
        : null,
      fechaMaxima: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
    }
  }, [fechaFin])

  const configuracionFechaFinal = useMemo(() => {
    form.trigger('fechaInicio')
    return {
      fechaMinima: fechaInicio
        ? moment(fechaInicio).format('YYYY-MM-DD')
        : null,
      fechaMaxima: fechaInicio
        ? moment(fechaInicio).add(6, 'months').format('YYYY-MM-DD')
        : null,
    }
  }, [fechaInicio])

  const ejecutarGeneracionReporte = async () => {
    try {
      const response = await generarReporte(form.getValues())
      if (!response.length)
        return contextoAplicacion.setModal2({
          abierto: true,
          titulo: 'No se encontraron datos para exportar',
          contenido: '',
          botones: [
            {
              nombre: 'Aceptar',
              click: () => {
                contextoAplicacion.cerrarModal('setModal')
                contextoAplicacion.cerrarModal('setModal2')
              },
            },
          ],
          clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
        })

      return contextoAplicacion.setModal2({
        abierto: true,
        titulo: 'El archivo ha sido generado',
        contenido: '',
        botones: [
          {
            nombre: 'Volver',
            click: () => {
              contextoAplicacion.cerrarModal('setModal')
              contextoAplicacion.cerrarModal('setModal2')
            },
          },
        ],
        clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
      })
    } catch (error) {
      console.log(error)
      contextoAplicacion.setModal2({
        abierto: true,
        titulo: 'Ocurrió un error generando el reporte',
        contenido: '',
        botones: [
          {
            nombre: 'Aceptar',
            click: () => {
              contextoAplicacion.cerrarModal('setModal')
              contextoAplicacion.cerrarModal('setModal2')
            },
          },
        ],
        clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
      })
    }
  }

  useEffect(() => {
    form.trigger()
  }, [])

  return (
    <div className="w-full">
      <h2 className="text-cendiatra w-full text-2xl font-bold mb-5 normal-case">
        Descargar registro en Excel
      </h2>
      <h6 className="normal-case text-cendiatra-rojo-1 font-bold text-sm flex flex-col w-full justify-items-start justify-start text-center pb-6">
        El filtro se puede aplicar con un máximo de seis meses
      </h6>
      <div className="w-full flex justify-center pb-6">
        <div className="grid grid-cols-2 w-6/12 gap-4">
          <div>
            <Controller
              control={form.control}
              name="fechaInicio"
              rules={{required: true}}
              render={({field, fieldState}) => {
                return (
                  <Input
                    estilosContenedor="w-full"
                    estilosInput={clsx(
                      'appearance-none rounded relative block w-full text-cendiatra-gris-3 p-1.5 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                      fieldState.error
                        ? 'border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1'
                        : 'border-cendiatra focus:border-cendiatra'
                    )}
                    mostrarErrores={true}
                    tipo={'date'}
                    titulo={'Fecha inicial'}
                    {...configuracionFechaInicio}
                    valor={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )
              }}
            />
          </div>
          <div>
            {' '}
            <Controller
              control={form.control}
              name="fechaFin"
              rules={{required: true}}
              render={({field, fieldState}) => {
                return (
                  <Input
                    estilosContenedor="w-full"
                    estilosInput={clsx(
                      'appearance-none rounded relative block w-full text-cendiatra-gris-3 p-1.5 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                      fieldState.error
                        ? 'border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1'
                        : 'border-cendiatra focus:border-cendiatra'
                    )}
                    mostrarErrores={true}
                    tipo={'date'}
                    titulo={'Fecha final'}
                    {...configuracionFechaFinal}
                    valor={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center">
        <Boton
          titulo="Regresar"
          lineaPrimerCaracter={true}
          habilitado={true}
          funcionCLick={() => contextoAplicacion.cerrarModal('setModal')}
          colorPorDefecto={'bg-transparent'}
          colorActivo={
            'opacity-70 text-cendiatra border border-transparent font-bold rounded-md'
          }
          tipo={'button'}
        />
        <Boton
          titulo="Continuar"
          lineaPrimerCaracter={true}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          habilitado={form.formState.isValid}
          funcionCLick={ejecutarGeneracionReporte}
        />
      </div>
    </div>
  )
}

const VerTiqueterasRegistradas = () => {
  const contextoAplicacion = useContext(ContextApplication)
  const [valoresFiltros, setValoresFiltros] = useState(filtrosIniciales)
  const [datos, setDatos] = useState({datos: [], paginacion: {}})
  const [cambioBusqueda, setCambioBusqueda] = useState(false)
  const {accounts} = useMsal()
  const usuarioRol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const csvRef = useRef(null)
  const [reporteCsv, setReporteCsv] = useState({
    data: [],
    headers: encabezadosReporteVerTiqueteras,
    filename: '',
  })
  const filtrosExternos = [
    {
      id: 1,
      estilos: 'w-15%',
      titulo: 'Fecha inicio',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fechaInicio',
      formato: 'input',
      valor: valoresFiltros.filtros.fechaInicio,
    },
    {
      id: 2,
      estilos: 'w-15%',
      titulo: 'Fecha fin',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      fechaMaxima: valoresFiltros.filtros.fechaInicio
        ? moment(valoresFiltros.filtros.fechaInicio)
            .add(30, 'days')
            .format('YYYY-MM-DD')
        : moment().add(30, 'days').format('YYYY-MM-DD'),
      nombre: 'fechaFin',
      formato: 'input',
      valor: valoresFiltros.filtros.fechaFin,
    },
    {
      id: 3,
      estilos: 'w-12%',
      titulo: 'NIT',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nit',
      formato: 'input',
      valor: valoresFiltros.filtros.nit,
    },
    {
      id: 4,
      estilos: 'w-12%',
      titulo: 'RAZÓN SOCIAL',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'razonSocial',
      formato: 'input',
      valor: valoresFiltros.filtros.razonSocial,
    },
    {
      id: 5,
      estilos: 'w-12%',
      titulo: 'Nº LIBRETA',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroLibreta',
      formato: 'input',
      valor: valoresFiltros.filtros.numeroLibreta,
    },
    {
      id: 5,
      estilos: 'w-12%',
      titulo: 'CONSECUTIVO DESDE O HASTA',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'consecutivo',
      formato: 'input',
      estiloLabel: 'w-full text-white text-14px h-auto',
      valor: valoresFiltros.filtros.consecutivo,
    },
    {
      id: 7,
      estilos: 'w-12%',
      titulo: 'Nº FACTURA',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroFactura',
      formato: 'input',
      valor: valoresFiltros.filtros.numeroFactura,
    },
  ]

  const manejarCambioEnFiltro = (e) => {
    setValoresFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: agregarFormatoFecha(e),
        },
      }
    })
  }

  const preparacionDato = async (dato) => {
    const ciudad = await ObtenerCiudadPorId(dato.ciudadId)
    const empresa = await ObtenerEmpresaPorId(dato.empresaId)

    return {
      ...dato,
      empresa: !!empresa ? {nombre: empresa.Nombre, nit: empresa.Nit} : null,
      ciudadNombre: ciudad?.[0]?.Nombre,
      fechaPago: moment(dato.fechaPago).isValid()
        ? moment(dato.fechaPago).format(
            FORMATOS_STANDARD.DD_MM_YYYY_SEP_BARRA_DIAGONAL
          )
        : dato.fechaPago,
    }
  }

  const obtenerInformacion = async () => {
    const response = await ObtenerTiqueterasGestionadas(valoresFiltros)
    const datosPromesas =
      response?.data?.datos?.map((dato) => preparacionDato(dato)) ?? []
    const datosPreparados = await Promise.all(datosPromesas)

    setDatos({
      datos: datosPreparados,
      paginacion: response?.data?.paginacion ?? {},
    })
  }

  const ejecutarAsignar = (row) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <FormularioAsignacionEntrega
          informacion={row}
          refrescarDatos={obtenerInformacion}
          precargarDatos
        />
      ),
      botones: [],
    })
  }

  const columnas = useMemo(() => {
    const items = [
      {
        accessor: 'id',
        className: 'w-0 hidden',
        headerStyle: '',
        disableFilters: true,
        cellStyles: '',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'FECHA'} />,
        accessor: 'fechaPago',
        className: 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'NIT-RAZÓN SOCIAL'} paddingFondoClaro={''} />
        ),
        accessor: 'nit',
        className: ROLES_VISUALIZACION.includes(usuarioRol)
          ? 'w-10%'
          : 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Cell: ({row}) => (
          <span>
            {row.original?.empresa?.nit
              .concat('-')
              .concat(row.original?.empresa?.nombre)}
          </span>
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'CIUDAD'} paddingFondoClaro={''} />
        ),
        accessor: 'ciudadNombre',
        className: 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'TIPO TIQUETERA'} paddingFondoClaro={''} />
        ),
        accessor: 'nombreTiquetera',
        className: ROLES_VISUALIZACION.includes(usuarioRol)
          ? 'w-11%'
          : 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'CANTIDAD'} paddingFondoClaro={''} />
        ),
        accessor: 'cantidad',
        className: 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Nº FACTURA'} paddingFondoClaro={''} />
        ),
        accessor: 'numeroFactura',
        className: 'w-11%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Cell: ({row}) => {
          return !row.original.numeroFactura ? (
            <AlertaSimple mensaje={'No generada'} />
          ) : (
            <InfoFacturaImpresion
              numeroFactura={row.original.numeroFactura}
              facturaId={row.original.facturaId}
            />
          )
        },
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'VALOR'} paddingFondoClaro={''} />
        ),
        accessor: 'valor',
        className: 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Cell: ({row}) => aplicarFormatoMoneda(row.original.valor),
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'QUIEN ENTREGA'} paddingFondoClaro={''} />
        ),
        accessor: 'ejecutivoComercial',
        className: 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all normal-case',
        Cell: () => 'Oscar Socha',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Nº LIBRETA'} paddingFondoClaro={''} />
        ),
        accessor: 'numeroLibreta',
        className: 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'CONSECUTIVO'} paddingFondoClaro={''} />
        ),
        accessor: 'consecutivo',
        className: 'w-9%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Cell: ({row}) => (
          <span>
            {row?.original?.consecutivoInicial}-
            {row?.original?.consecutivoFinal}
          </span>
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'COMERCIAL'} paddingFondoClaro={''} />
        ),
        accessor: 'responsableEntrega',
        className: ROLES_VISUALIZACION.includes(usuarioRol) ? 'w-12%' : 'w-10%',
        headerStyle: clsx(
          'bg-cendiatra-verde-7 text-white h-16',
          ROLES_VISUALIZACION.includes(usuarioRol) && 'rounded-r-lg'
        ),
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      },
    ]

    if (!ROLES_VISUALIZACION.includes(usuarioRol)) {
      items.push({
        id: 'button',
        className: 'w-7.69%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pl-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Cell: ({row}) => (
          <div className="w-full flex justify-center items-center">
            <BotonSimple
              texto={'Modificar'}
              estilosBoton={clsx(
                `w-24 h-8 text-white bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`,
                !row.original.numeroFactura &&
                  'bg-cendiatra-gris-placeholder pointer-events-none',
                !!row.original.numeroFactura && 'bg-btnBg cursor-pointer'
              )}
              funcion={() => ejecutarAsignar(row.original)}
              deshabilitado={!row.original.numeroFactura}
            />
          </div>
        ),
      })
    }

    return items
  }, [usuarioRol])

  const generarReporte = async (payload) => {
    const {
      filtros: {ciudadId, sedeId},
    } = valoresFiltros
    const response = await FachadaObtenerInformeTiqueterasGestionadas({
      ...payload,
      ciudadId,
      sedeId,
    })
    const datosPromesas =
      response?.data?.map((dato) => preparacionDato(dato)) ?? []
    const promesasResueltas = await Promise.all(datosPromesas)
    const datosPreparados = promesasResueltas.map((dato) => ({
      ...dato,
      configEmpresa: `${dato.empresa.nit}-${dato.empresa.nombre}`,
      consecutivo: `${dato.consecutivoInicial}-${dato.consecutivoFinal}`,
      comercial: dato.responsableEntrega,
      responsableEntrega: 'Oscar Socha',
    }))

    setReporteCsv((prv) => ({
      ...prv,
      data: datosPreparados,
      filename: `reporte_ver_tiqueteras_generadas_${moment().format(
        'DDMMYYYYHHmmss'
      )}.csv`,
    }))
    return datosPreparados
  }

  const mostrarDialogoReporte = async () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: <GenerarReporte generarReporte={generarReporte} />,
      botones: [],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const configuracionFiltroPorRol = async () => {
    let ciudadId = null
    let sedeId = null
    if (usuarioRol === ROLES_APP.GERENTE_COMERCIAL) {
      const ciudad = await ObtenerCiudadPorNombre('BOGOTÁ')
      ciudadId = +(ciudad?.Id ?? 0)
    } else if (usuarioRol === ROLES_APP.JEFE_SEDE) {
      sedeId = fnObtenerInformacionSessionOLocalStorage('sedeId', usuarioRol)
    }

    setValoresFiltros((prv) => ({
      ...prv,
      filtros: {
        ...prv.filtros,
        ciudadId,
        sedeId,
      },
    }))
    setCambioBusqueda(true)
  }

  useEffect(() => {
    if (!usuarioRol) return
    AgregarEmpresasACache().then(() => configuracionFiltroPorRol())
  }, [usuarioRol])

  useEffect(() => {
    if (!cambioBusqueda) return
    setCambioBusqueda(false)
    obtenerInformacion()
  }, [cambioBusqueda])

  useEffect(() => {
    if (!reporteCsv.data.length) return
    csvRef.current.link.click()
    setReporteCsv((prv) => ({...prv, data: [], filename: ''}))
  }, [reporteCsv.data])

  return (
    <ContenedorPagina
      tituloPagina={
        ROLES_VISUALIZACION.includes(usuarioRol)
          ? 'Ver tiqueteras registradas'
          : 'Ver/Modificar tiqueteras registradas'
      }
    >
      <div className="w-full">
        <div className="w-full flex justify-center">
          <span className="text-cendiatra-rojo-1 font-bold">
            El filtro se puede aplicar con máximo de un mes
          </span>
        </div>
        <div>
          <ComponenteConjuntoDeFiltros
            filtrosPorCrear={filtrosExternos}
            funcionBoton={obtenerInformacion}
          />
        </div>
        <div>
          <TablaConPaginacionYFiltros
            data={datos.datos}
            columns={columnas}
            paginacionBackend
            parametrosPaginacionConBackEnd={datos.paginacion}
            filasPorPagina={10}
            maximoDePaginasparaMostrar={10}
            funcionCambiarPagina={(numeroPagina) => {
              setValoresFiltros((prv) => ({...prv, pagina: numeroPagina}))
              setCambioBusqueda(true)
            }}
            usarSort={false}
          />
          <div className="w-full flex justify-end items-center -mt-20">
            <CSVLink {...reporteCsv} ref={csvRef} />
            <BotonSimple
              texto={'Descargar reporte'}
              estilosBoton={
                'w-50 p-1 h-10 rounded-lg bg-cover bg-btnBg bg-no-repeat text-white text-lg border border-white'
              }
              funcion={mostrarDialogoReporte}
              tipoDeBoton={'button'}
            />
          </div>
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default VerTiqueterasRegistradas

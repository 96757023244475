import {useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {
  ObtenerInformacionCiudadPorId,
  ObtenerDepartamentoPorId,
} from '../../cache/servicios-cache'
import {PATRON_ES_CORREO, ROLES_APP, TEXTO_VISUAL} from '../../constantes'
import {useAutocompletarCiudad} from '../../hooks/ciudades/useAutocompletarCiudad'
import {useAutocompletarDepartamento} from '../../hooks/departamentos/useAutocompletarDepartamento'
import {useTiposDocumento} from '../../hooks/tiposDocumento/useTiposDocumento'
import {construirEstilosInput} from '../../utilidades'
import {Boton} from '../Botones'
import {CampoFiltrarEspañol, Fila} from '../index'
import {ErroresDeCampo} from '../Formularios'
import {Input, ListaDesplegable} from '../inputs'
import moment from 'moment'
import {useMsal} from '@azure/msal-react'

const TEXTO_PANTALLA =
  TEXTO_VISUAL.GESTION_FACTURACION
    .CORRECCION_ERRORES_TRANSMISION_FIRMA_ELECTRONICA

const NO_PERMITIR_EMISION = [
  ROLES_APP.RECEPCION,
  ROLES_APP.ADMINISTRADOR_CONTABLE,
]

export const FormularioCorreccionFactura = ({
  informacionFormulario,
  manejadorGuardar,
}) => {
  const [formularioActualTieneErrores, setFormularioActualTieneErrores] =
    useState(true)
  const [tiposDocumentos] = useTiposDocumento()
  const [filtrarDepartamentos, departamentosFiltrados] =
    useAutocompletarDepartamento()
  const [filtrarCiudades, ciudadesFiltradas] = useAutocompletarCiudad()
  const [deshabilitadoInicial, setDeshabilitadoInicial] = useState(true)
  const {accounts} = useMsal()
  const rol = accounts[0]?.idTokenClaims?.roles[0] ?? ''

  const {
    control,
    clearErrors,
    formState: {errors, isValid},
    getValues,
    reset,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      id: '',
      tipoDocumento: '',
      identificacion: '',
      codigoVerificacion: '',
      nombreCliente: '',
      departamentoId: '',
      departamento: '',
      municipioId: '',
      municipio: '',
      direccion: '',
      codigoPostal: '',
      correo: '',
      actividadEconomica: '',
      matricula: '',
      responsabilidadFiscal: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    setFormularioActualTieneErrores(
      !isValid ||
        Object.keys(errors).length !== 0 ||
        getValues().departamentoId?.length === 0 ||
        (getValues().departamentoId?.length > 0 &&
          getValues().municipioId?.length === 0)
    )
    trigger()
  }, [isValid, errors, getValues])

  useEffect(() => {
    reset(informacionFormulario)
  }, [])

  useEffect(() => {
    let subscripcion = watch((value) => {
      let seguirDeshabilitado = true
      for (const key in value) {
        const valorQueLlego = informacionFormulario[key]
        const valorWatch = value[key]
        if (valorQueLlego !== undefined) {
          if (valorQueLlego !== valorWatch) {
            seguirDeshabilitado = !formularioActualTieneErrores
          }
        }
      }
      setDeshabilitadoInicial(seguirDeshabilitado)
    })
    return () => subscripcion.unsubscribe()
  }, [watch, informacionFormulario, formularioActualTieneErrores])

  useEffect(() => {
    if (
      typeof informacionFormulario === 'object' &&
      Object.values(informacionFormulario).filter(
        (valor) => valor !== undefined
      ).length > 0
    ) {
      const promesaNombreVacio = () => Promise.resolve({Nombre: ''})
      let promesaDeparmaento =
        informacionFormulario.departamentoId !== null
          ? ObtenerDepartamentoPorId(informacionFormulario.departamentoId)
          : promesaNombreVacio()

      let promesaCiudad =
        informacionFormulario.municipioId !== null
          ? ObtenerInformacionCiudadPorId(informacionFormulario.municipioId)
          : promesaNombreVacio()

      Promise.all([promesaDeparmaento, promesaCiudad]).then(
        ([departamento, ciudad]) => {
          reset({
            ...informacionFormulario,
            departamento: departamento.Nombre,
            municipio: ciudad.Nombre,
          })
          trigger()
        }
      )
    }
  }, [informacionFormulario])

  const permitirTransmisionAnnoActual = useMemo(() => {
    if (!informacionFormulario && !rol) return false

    if (!NO_PERMITIR_EMISION.includes(rol)) return true

    const anno = moment(informacionFormulario.fechaEmision)
    return anno.isValid() && anno.get('year') === moment().get('year')
  }, [informacionFormulario, rol])

  const manejarDepartamentoCambiado = async (e) => {
    const textoEscrito = e.target.value

    setError('departamento', {type: 'require', message: ''})
    setValue('departamento', textoEscrito)
    setValue('departamentoId', '')
    setValue('municipioId', '')
    setValue('municipio', '')

    await filtrarDepartamentos(textoEscrito)
  }

  const manejarDepartamentoSeleccionado = (departamentoSeleccionado) => {
    setValue('departamentoId', departamentoSeleccionado.id)
    setValue('departamento', departamentoSeleccionado.filtro)
    clearErrors('departamentoId')
    setError('municipio', {type: 'require', message: ''})
  }

  const manejarCiudadCambiada = async (e) => {
    const textoEscrito = e.target.value

    setError('municipio', {type: 'require', message: ''})
    setValue('municipio', textoEscrito)
    setValue('municipioId', '')

    await filtrarCiudades(getValues().departamento, textoEscrito)
  }

  const manejarCiudadSeleccionada = (ciudadSeleccionada) => {
    setValue('municipioId', ciudadSeleccionada.id)
    setValue('municipio', ciudadSeleccionada.filtro)
    clearErrors('municipio')
  }

  const manejarGuardar = () => {
    if (typeof manejadorGuardar === 'function') {
      manejadorGuardar(getValues())
    }
  }

  return (
    <>
      <Fila
        alineacionHorizontal="justify-evenly"
        alineacionVertical="items-start"
        ancho="w-full mt-4"
        flexWrap={true}
      >
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="tipoDocumento"
            control={control}
            rules={{required: true}}
            render={({field: {name, onChange, value}}) => (
              <ListaDesplegable
                estilosContenedor="w-5/6"
                estilosLista={construirEstilosInput(errors, name, false)}
                titulo={TEXTO_PANTALLA.FORMULARIO.TIPO_DOCUMENTO}
                opciones={tiposDocumentos}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="identificacion"
            control={control}
            rules={{required: true}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-7/12 mr-4"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.IDENTIFICACION}
                valor={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="codigoVerificacion"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-2/12"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.CODIGO_VERIFICACION}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="nombreCliente"
            control={control}
            rules={{required: true}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.CLIENTE}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </Fila>
      <Fila
        alineacionHorizontal="justify-evenly"
        alineacionVertical="items-start"
        ancho="w-full mt-4"
        flexWrap={true}
      >
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="departamento"
            control={control}
            rules={{required: false}}
            render={({field: {name, value}}) => (
              <CampoFiltrarEspañol
                estilosPersonalizados="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                informacionFiltrada={departamentosFiltrados}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.DEPARTAMENTO}
                valorDelCampoFiltro={value}
                handleChange={manejarDepartamentoCambiado}
                handleOptionChange={manejarDepartamentoSeleccionado}
                desactivarOtroFiltro={false}
              />
            )}
          />
        </div>
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="municipio"
            control={control}
            rules={{required: false}}
            render={({field: {name, value}}) => (
              <CampoFiltrarEspañol
                estilosPersonalizados="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                informacionFiltrada={ciudadesFiltradas}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.CIUDAD}
                valorDelCampoFiltro={value}
                handleChange={manejarCiudadCambiada}
                handleOptionChange={manejarCiudadSeleccionada}
                desactivarOtroFiltro={false}
              />
            )}
          />
        </div>
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="direccion"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.DIRECCION}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </Fila>
      <Fila
        alineacionHorizontal="justify-evenly"
        alineacionVertical="items-start"
        ancho="w-full mt-4"
        flexWrap={true}
      >
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="codigoPostal"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.CODIGO_POSTAL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="actividadEconomica"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.ACTIVIDAD_ECONOMICA}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="matricula"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.MATRICULA}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </Fila>
      <Fila
        alineacionHorizontal="justify-start"
        alineacionVertical="items-start"
        ancho="w-full mt-4"
        flexWrap={true}
      >
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="responsabilidadFiscal"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="text"
                titulo={TEXTO_PANTALLA.FORMULARIO.CASILLA_53}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-1/3 flex justify-start flex-wrap">
          <Controller
            name="correo"
            control={control}
            rules={{
              required: true,
              pattern: {
                message: TEXTO_VISUAL.FORMULARIO.CORREO_INVALIDO,
                value: PATRON_ES_CORREO,
              },
            }}
            render={({field: {name, onChange, value}}) => (
              <Input
                estilosContenedor="w-5/6"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="email"
                titulo={TEXTO_PANTALLA.FORMULARIO.CORREO_ELECTRONICO}
                valor={value}
                onChange={onChange}
              />
            )}
          />
          <ErroresDeCampo erroresFormulario={errors} nombre="correo" />
        </div>
      </Fila>
      <Fila
        alineacionHorizontal="justify-center"
        ancho="w-full mt-12"
        flexWrap={true}
      >
        <Boton
          ancho="w-28"
          titulo={TEXTO_PANTALLA.FORMULARIO.BOTON_GUARDAR}
          funcionCLick={manejarGuardar}
          habilitado={
            (!formularioActualTieneErrores || !deshabilitadoInicial) &&
            permitirTransmisionAnnoActual
          }
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
        />
      </Fila>
    </>
  )
}
